import React from "react";
import page5 from "../img/larv-guide/page5.png";
import page6 from "../img/larv-guide/page6.png";

export default function GuideGL() {
	return (
		<div className="larvGuide">
			<img src={page5} alt="" />
			<img src={page6} alt="" />
		</div>
	);
}
